import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./App.scss";

import Home from "./pages/Home/Home";
import MainPage from "./pages/MainPage/MainPage";
import Product from "./pages/Products/Products";
import Solutions from "./pages/Solutions/Solutions";
import Contact from "./pages/Contacts/Contacts";
import Company from "./pages/Company/Company";
import ProductMobile from "pages/Products/ProductMobile";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import TermsAndConditionsChannels from "pages/TermsOfServicesChannels/TermsOfServicesChannels";
import ExtensionApp from "pages/ExtensionApp/ExtensionApp";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import TermsOfService from "pages/ExtensionApp/TermsOfService";
import PrivacyPolicy from "pages/ExtensionApp/PrivacyPolicy";

const apps = [
  { id: 1, name: "313 Hood videos from around th", path: "313-hood-videos" },
  { id: 2, name: "City Walks", path: "city-walks" },
  { id: 3, name: "Vision Environnement", path: "vision-environnement" },
  { id: 4, name: "Big Rig Travels", path: "big-rig-travels" },
  { id: 5, name: "RailCowGirl", path: "railcowgirl" },
  { id: 6, name: "The Flying Dutchman", path: "the-flying-dutchman" },
  { id: 7, name: "Tourister - Virtual City", path: "tourister-virtual-city" },
  { id: 8, name: "David Hoffman", path: "david-hoffman" },
  { id: 9, name: "Keezi Walks", path: "keezi-walks" },
];

function Layout() {
  return (
    <div className="wrapper">
      <div className="header">
        <Header />
      </div>
      <div className="content">
        {/* This will render the content of the current route */}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Routes with Layout (Header and Footer) */}
        <Route element={<Layout />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<Product />} />
          <Route path="/products-mobile" element={<ProductMobile />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/company" element={<Company />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          {apps.map((app) => (
            <Route
              key={app.id}
              path={`/channels/terms-and-conditions/${app.path}`}
              element={
                <TermsAndConditionsChannels
                  appName={app.name}
                  appUrl={app.url}
                />
              }
            />
          ))}
        </Route>

        {/* Route without Header and Footer */}
        <Route path="/extension" element={<ExtensionApp />} />
        <Route
          path="/extension/terms-of-service"
          element={<TermsOfService />}
        />
        <Route path="/extension/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
