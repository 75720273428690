import React from "react";

export default function PrivacyPolicy() {
  return (
    <div style={{ padding: "36px" }}>
      {" "}
      <h1>Privacy Policy for Safe Receipts AI</h1>
      <p>
        <strong>Effective Date:</strong> 25.03.2025
      </p>
      <p>
        At <strong>Safe Receipts AI</strong>, we take your privacy seriously.
        This Privacy Policy explains how we collect, use, and protect your
        personal information when you use our service.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        We collect personal information that you provide to us when using the
        Service. This may include:
      </p>
      <ul>
        <li>Your name and email address</li>
        <li>Payment information (for subscription services)</li>
        <li>Usage data, including the receipts you scan and store</li>
      </ul>
      <h2>2. How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide the features and services of Safe Receipts AI</li>
        <li>Improve and personalize your experience</li>
        <li>
          Communicate with you regarding updates, new features, or issues with
          your account
        </li>
        <li>Process payments for the subscription</li>
      </ul>
      <h2>3. Data Security</h2>
      <p>
        We implement security measures to protect your personal data from
        unauthorized access. However, no system is completely secure, and we
        cannot guarantee the security of your data.
      </p>
      <h2>4. Sharing Your Information</h2>
      <p>
        We do not share your personal data with third parties except as
        necessary to provide the Service, comply with legal obligations, or
        protect our rights.
      </p>
      <h2>5. Cookies</h2>
      <p>
        We use cookies to enhance your experience on our website. These cookies
        may store information about your preferences and activity on our site.
      </p>
      <h2>6. Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal
        information. You can also object to the processing of your data in
        certain circumstances.
      </p>
      <h2>7. Third-Party Links</h2>
      <p>
        Our Service may contain links to third-party websites. We are not
        responsible for the privacy practices of these sites.
      </p>
      <h2>8. Modifications to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated effective date.
      </p>
      <h2>9. Contact Information</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p>
        <strong>Commodified Media Ltd.</strong>
        Suite 4.3.02, Block 4, Eurotowers Email:{" "}
        <a href="mailto:theoffice@commodifiedimage.com">
          theoffice@commodifiedimage.com
        </a>
      </p>
    </div>
  );
}
