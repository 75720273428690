import React from "react";

export default function TermsOfService() {
  return (
    <div style={{ padding: "64px" }}>
      <h1>Terms of Service for Safe Receipts AI</h1>
      <p>
        <strong>Effective Date:</strong> 25/03/2025
      </p>

      <p>
        Welcome to <strong>Safe Receipts AI</strong>. These Terms of Service
        ("Terms") govern your use of our website, application, and services
        provided by <strong>Commodified Media Ltd.</strong> ("we", "us", "our").
        By accessing or using the Safe Receipts AI application ("Service"), you
        agree to be bound by these Terms.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using the Safe Receipts AI service, you acknowledge that you have
        read, understood, and agree to these Terms and any other rules or
        policies that may apply.
      </p>

      <h2>2. User Account</h2>
      <p>
        To access certain features of the Safe Receipts AI service, you may be
        required to create an account. You agree to provide accurate and
        complete information when registering and to update your information as
        necessary.
      </p>

      <h2>3. License to Use the Service</h2>
      <p>
        We grant you a limited, non-exclusive, non-transferable license to use
        the Service in accordance with these Terms.
      </p>

      <h2>4. User Obligations</h2>
      <p>
        You agree not to use the Service in any way that violates applicable law
        or infringes on the rights of others. This includes but is not limited
        to:
      </p>
      <ul>
        <li>Engaging in fraudulent activity</li>
        <li>Distributing harmful software</li>
        <li>Accessing the Service with the intent to disrupt its operations</li>
      </ul>

      <h2>5. Privacy Policy</h2>
      <p>
        Our Privacy Policy governs how we collect, use, and disclose your
        personal information. Please review it for more details about how we
        handle your data.
      </p>

      <h2>6. Limitations of Liability</h2>
      <p>
        In no event shall Commodified Media Ltd. be liable for any indirect,
        incidental, special, or consequential damages arising out of your use or
        inability to use the Service.
      </p>

      <h2>7. Modifications</h2>
      <p>
        We reserve the right to update or modify these Terms at any time. If we
        make changes, we will notify you via email or through the Service.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms will be governed by and construed in accordance with the
        laws of [Insert Country].
      </p>

      <h2>9. Contact Information</h2>
      <p>For any questions about these Terms, please contact us at:</p>
      <p>
        <strong>Commodified Media Ltd.</strong>
        Suite 4.3.02, Block 4, Eurotowers Email:{" "}
        <a href="mailto:theoffice@commodifiedimage.com">
          theoffice@commodifiedimage.com
        </a>
      </p>
    </div>
  );
}
