import React from "react";
import styles from "./style.module.scss";
import applogo from "assets/ExtensionApp/applogo.png";

export default function ExtensionApp() {
  return (
    <div>
      <div className={styles.header}>
        Safe Receipts AI – Your Smart Receipt Manager
      </div>
      <div className={styles.container}>
        <img
          className={styles.logo}
          src={applogo}
          alt="Safe Receipts AI Logo"
        />
        <a
          className={styles.btn}
          href="https://chromewebstore.google.com/detail/safe-receipts-ai-%E2%80%93-auto-s/lgjhmiemlbcfkcmnjbkjogjikjenioej"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get the Extension
        </a>
        <h1>Effortless Receipt Management with AI</h1>
        <p>
          Say goodbye to paper receipts and manual organization. Safe Receipts
          AI scans, categorizes, and stores your receipts securely in the cloud.
          Manage expenses effortlessly and generate reports in just a few
          clicks.
        </p>

        <div className={styles.features}>
          <h2>Why Choose Safe Receipts AI?</h2>
          <ul>
            <li>Automatic receipt scanning and categorization</li>
            <li>Secure cloud storage with easy access</li>
            <li>Seamless integration with Google Drive</li>
            <li>AI-powered smart detection for enhanced accuracy</li>
            <li>Export receipts and generate financial reports</li>
            <li>Perfect for businesses and personal finance tracking</li>
          </ul>
        </div>

        <div className={styles.howItWorks}>
          <h2>How It Works</h2>
          <ul>
            <li>Install the Safe Receipts AI Chrome Extension</li>
            <li>Sign in with your Google account</li>
            <li>Let AI categorize and store them securely</li>
            <li>Access, export, or analyze your receipts anytime</li>
          </ul>
        </div>
      </div>
      <div className={styles.footer}>
        <footer>
          <a
            href="https://commodified.me/extension/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{" "}
          |{" "}
          <a
            href="https://commodified.me/extension/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </footer>{" "}
        © 2025 Safe Receipts AI. All rights reserved.
      </div>
    </div>
  );
}
